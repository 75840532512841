import './App.css';
import WOW from 'wowjs';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar/Navbar';
import Intro from './components/Intro/Intro';
import FAQ from './components/FAQ/FAQ';
import Footer from './components/Footer/Footer';
import BackToTopButton from './components/BackToTopButton/BackToTopButton';
import MainSponsor from './components/MainSponsor/MainSponsor';
import SponsorMapSection from './components/SponsorMapSection/SponsorMapSection';

function App() {

  useEffect(() => {

    new WOW.WOW({
      live: false
    }).init();
  }, [])

  return (

    <Router>

      <Navbar />

      <Routes>
        <Route exact path="/" element={<Intro name='MORZAT' />} />
        <Route exact path="/GULSE_ATUN" element={<Intro name='GÜLSE ATUN' />} />
        <Route path="*" element={<Intro name={window.location.pathname.slice(1)} />}></Route>
      </Routes>

      <SponsorMapSection />

      <FAQ />
      <Footer />
      <BackToTopButton />

    </Router>
  );
}

/*todo
    add venobox init(example at old-site main.js)
    
*/

export default App;

import React from 'react'
import './Footer.css'
import WhiteLogo from '../../assets/logos/ACM-White.png'

function Footer() {

    return (

        <footer id="footer">

            <div className="footer-top">

                <div className="container">

                    <div className="row d-flex justify-content-between">

                        <div className="col-lg-3 col-md-6 footer-info">

                            <a href="https://www.acmhacettepe.com/" target="_blank">

                                <img src={WhiteLogo} alt="TheEvenet" />
                            </a>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-contact ">

                            <h4>Bize Ulaşın</h4>

                            <strong>Kommunity:</strong> <a href="https://kommunity.com/acmhacettepe">kommunity.com/acmhacettepe</a><br />

                            <strong>Email:</strong> info@acmhacettepe.com<br />

                            <div className="social-links">

                                <a href="https://www.youtube.com/channel/UCj9lz6_BSlGaiiq1IySGo_A" className="youtube">

                                    <i className="fa fa-youtube" />
                                </a>

                                <a href="https://www.instagram.com/acmhacettepe/" className="instagram">

                                    <i className="fa fa-instagram" />
                                </a>

                                <a href="https://www.linkedin.com/company/acm-hacettepe" className="linkedin">

                                    <i className="fa fa-linkedin" />
                                </a>

                                <a href="https://twitter.com/acmhacettepe" className="twitter">

                                    <i className="fa fa-twitter" />
                                </a>
                                <a href="https://tr-tr.facebook.com/acmhacettepe" className="facebook"><i className="fa fa-facebook"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">

                <div className="copyright">

                    &copy; Copyright <strong>ACM Hacettepe</strong>. All Rights Reserved
                </div>
            </div>
        </footer>
    )
}

export default Footer
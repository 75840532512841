import React, { useState, useEffect } from 'react'
import './BackToTopButton.css'

function BackToTopButton() {

  const [active, setActive] = useState(false)

  const scrollToTop = () => {

    window.scrollTo({
      top: 100,
      behavior: "smooth",
    })
  }

  const handleScroll = () => {

    if (window.scrollY > 100) setActive(true)
    else setActive(false)
  }

  useEffect(() => {

    document.addEventListener("scroll", handleScroll);

    return () => document.removeEventListener("scroll", handleScroll);
  });


  return (

    <span className={active ? 'back-to-top active' : 'back-to-top not-active'} onClick={scrollToTop}>

      <i className="fa fa-angle-up" />
    </span>

  )
}

export default BackToTopButton
import React, { useState } from 'react'
import './SponsorMapSection.css'
import Sponsors from './Sponsors/Sponsors'
import Maps from './Maps/Maps'
import company from '../../company.json'

function SponsorMapSection() {

    const [selection, setSelection] = useState(true)

    return (

        <section className='sponsor-map-section' id= 'sponsors'>

            <div className="container wow fadeInUp">

                <div className="section-header">

                    <h2>SPONSORLUKLARIMIZ</h2>
                </div>
            </div>

            <div className='selection-container'>

                <span
                    onClick={() => setSelection(!selection)}
                    className={selection ? 'selection selected' : 'selection'}
                >

                    LİSTE
                </span>

                <span
                    onClick={() => setSelection(!selection)}
                    className={selection ? 'selection' : 'selection selected'}
                >

                    HARİTA
                </span>
            </div>

            <div className='screen'>

                {selection && <Sponsors companies = {company.companies}/>}
                {!selection && <Maps maps = {company.maps}/>}
            </div>
        </section>
    )
}

export default SponsorMapSection


/*<input type="checkbox" class="onoffswitch-checkbox" id="inline" checked>  */
import React, { useRef, useState } from 'react';

function IntroCard(props) {
  const [light, setLight] = useState("");
  const [angle, setAngle] = useState(0);
  const [cardStyle, setCardStyle] = useState({ transform: '0' });
  const [flipped, setFlipped] = useState(false);

  const divRef = useRef();

  const handleMouseEnter = () => {
    const bounds = divRef.current.getBoundingClientRect();

    const handleMouseMove = (e) => {
      const mouseX = e.clientX;
      const mouseY = e.clientY;

      const leftX = mouseX - bounds.x;
      const topY = mouseY - bounds.y;

      const center = {
        x: leftX - bounds.width / 2,
        y: topY - bounds.height / 2,
      };

      const distance = Math.sqrt(center.x ** 2 + center.y ** 2);
      const angle = Math.log(distance) * 2;
      const light = `
        radial-gradient(
          circle at ${center.x * 2 + bounds.width / 2}px ${center.y * 2 + bounds.height / 2}px,
          #ffffff55, #0000000f
        )
      `;

      setLight(light);
      setAngle(angle);
      setCardStyle({
        transform: `scale3d(1.07, 1.07, 1.07) rotate3d(${center.y / 100}, ${-center.x / 100}, 0, ${angle}deg)`
      });
    };

    divRef.current.addEventListener("mousemove", handleMouseMove);

    return () => {
      divRef.current.removeEventListener("mousemove", handleMouseMove);
    };
  };

  const handleMouseLeave = () => {
    setAngle(0);
    setLight("");
    setCardStyle({
      transform: 'scale3d(1,1,1) rotate3d(0,0,0,0)'
    });
  };

  const handleCardClick = () => setFlipped(!flipped);

  return (
    <div
      className={`card ${flipped ? 'flipped' : ''}`}
      style={cardStyle}
      ref={divRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleCardClick}
    >
      <div className="card-inner">
        {/* Front Side */}
        <div className="card-front">
          <div className="glow" style={{ background: light }} />
          <div className="userinfo" id="userinfo">
            {props.name.replace(/[uiocsg_]/g, (match) => {
              const replacements = { 'u': 'Ü', 'i': 'İ', 'o': 'Ö', 'c': 'Ç', 's': 'Ş', 'g': 'Ğ', '_': ' ' };
              return replacements[match];
            })}
          </div>
        </div>

        {/* Back Side */}
        <div className="card-back">
          <div className="glow" style={{ background: light }} />

        </div>
      </div>
    </div>
  );
}

export default IntroCard;

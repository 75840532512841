import React from "react";
import './Faq.css';
import './Faq-content';
import { faq } from "./Faq-content";

export function FAQ() {

    return (

        <section id="faq" className="wow fadeInUp">

            <div className="container">

                <div className="section-header">

                    <h2>{faq.title}</h2>
                </div>

                <div className="row justify-content-center">

                    <div className="col-lg-9">

                        <ul id="faq-list">

                            <li>

                                <a data-toggle="collapse" href="#faq4" className="collapsed">{faq["acm-hacettepe-title"]}<i className="fa fa-minus-circle"></i></a>

                                <div id="faq4" className="collapse" data-parent="#faq-list">

                                    <p>{faq["acm-hacettepe"]}</p>
                                </div>
                            </li>

                            <li>

                                <a data-toggle="collapse" href="#faq5" className="collapsed">{faq["acm-global-title"]} <i className="fa fa-minus-circle"></i></a>

                                <div id="faq5" className="collapse" data-parent="#faq-list">

                                    <p>{faq["acm-global"]}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FAQ
import React from 'react'
import './Intro.css'
import IntroCard from './IntroCard'

function Intro(props) {

  return (

    <section id="intro">

        <div className="intro-container wow fadeIn">

          <IntroCard name = {props.name}/>

          <a href="#sponsors" className="about-btn scrollto">Sponsorluklarımız</a>
        </div>
      </section>
  )
}

export default Intro
import React, { useState } from 'react'

function SponsorCard(props) {

    const [open, setOpen] = useState(false)

    const colorMap = {

        20: '#7711b6',
        15: '#3ccb19',
        10: 'orange',
    }

    return (

        <div className="schedule-item" onClick={() => setOpen(!open)}>

            <div className='item-title'>

                <div className='discount-circle' style={{ backgroundColor: colorMap[props.ammount] }}>

                    {'%' + props.ammount}
                </div>

                <div className="col-md-9">

                    <h4>{props.name}</h4>

                    <p>{props.description}</p>
                </div>

                {<div className="fa fa-angle-down dropdown-arrow" style={{ opacity: props.mapLocation ? "100%" : "0%" }} />}
            </div>

            {
                props.mapLocation &&
                <div className={open ? 'dropdown-map dropdown-active' : 'dropdown-map not-active'}>

                    <div className='map-container'>

                        {open && <iframe src={props.mapLocation} width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />}
                    </div>
                </div>
            }
        </div>
    )
}

export default SponsorCard
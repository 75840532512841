export const faq = {
    'title': 'S.S.S ',
    'acm-global-title': 'ACM Nedir?',
    'acm-global': `ACM (Association for Computing Machinery), bilgisayar mühendisliği bilimini bir araya getirmek için
    1947'de kurulmuş ve gün geçtikçe büyüyerek dünya çapında tanınan bir kuruluş olmuştur. Merkezi New
    York şehrinde bulunan ACM, dünya genelinde çeşitli şubeleri, profesyonel kulüplerini, özel araştırma
    gruplarını ve öğrenci kollarını bünyesinde barındırmaktadır.`,
    'acm-hacettepe-title': 'ACM Hacettepe Nedir?',
    'acm-hacettepe': `ACM Hacettepe Öğrenci Topluluğu 2010 yılında kurulmuş olup ACM'in Türkiye'de bulunan 13 öğrenci
    kolundan biridir. Topluluğumuz kurulduğu günden bu yana her geçen gün artan üye sayısıyla Hacettepe
    Üniversitesi'nin en büyük topluluğu haline gelmiştir.`
}
import React, { useState, useEffect } from 'react'
import './Navbar.css'
import ACMLogo from '../../assets/logos/ACM-White.png'

function Navbar() {

    const [sticky, setSticky] = useState(false)

    const handleScroll = () => {

        if (window.scrollY > 100 && window.innerWidth > 990) setSticky(true)
        else setSticky(false)
    }

    useEffect(() => {

        document.addEventListener("scroll", handleScroll);
        return () => document.removeEventListener("scroll", handleScroll);
    });


    return (

        <header id="header" className={sticky ? 'sticky' : 'not-sticky'}>

            <div className="container">

                <div id="logo">

                    <a href="https://www.acmhacettepe.com/" target="_blank"><img src={ACMLogo} alt="" title="" /></a>
                </div>

                <nav id="nav-menu-container" >

                    <ul className='nav-menu'>

                        <li><a href="#intro">Anasayfa</a></li>

                        <li><a href="#sponsors">Sponsorluklarımız</a></li>

                        <li><a href="#supporters">Kart Sponsorumuz</a></li>

                        <li><a href="#faq">S.S.S.</a></li>

                        <li><a href="#footer">İletişim</a></li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default Navbar
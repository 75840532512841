import React, { useState } from 'react'
import './Maps.css'

function Maps(props) {

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [activeFilter, setActiveFilter] = useState(0)

  const filters = [

    {

      name: 'Hepsi',
      to: 'all',
    },

    {

      name: 'Bahçeli',
      to: 'bahceli',
    },

    {

      name: 'Tunalı',
      to: 'tunali',
    },

    {

      name: 'Ümitköy',
      to: 'umitkoy',
    },
  ]

  return (

    <section >

      <div className='filter-container'>

        <div className={dropdownOpen ? 'filter-dropdown dropdown-opened' : 'filter-dropdown dropdown-closed'}>

          <span
            className={dropdownOpen ? 'filter-btn selected' : 'filter-btn'}
            onClick={() => { setDropdownOpen(!dropdownOpen) }}
          >

            Filtrele
          </span>

          {dropdownOpen && filters.map((filter, i) => {
            return (
              <span
                className={activeFilter == i ? 'filter-btn selected' : 'filter-btn'}
                onClick={() => { setActiveFilter(i) }}
              >

                {filter.name}
              </span>
            )
          })
          }

        </div>
      </div>

      <div 
        className='map-container'
        onClick={() => setDropdownOpen(false)}
      >

        <iframe src={props.maps[filters[activeFilter].to]} width="100%" height="100%" loading="lazy"/>
      </div>
    </section>
  )
}

export default Maps